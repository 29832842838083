import instantsearch from 'instantsearch.js/es';
import { hits } from 'instantsearch.js/es/widgets';

export function initSearch() {
	if ( typeof instantsearch === 'undefined' ) {
		return;
	}

	const search_container = document.querySelector( '.search' );
	if ( !search_container ) {
		return;
	}
	const hits_box = search_container.querySelector( '.search__hits' );
	const search = instantsearch( {
		appId: 'I0WU9CRQ1Z',
		apiKey: '834a730964e067929f547d230693aaf5',
		indexName: 'dev_BCO'
	} );

	const search_box = {
		init: function( opts ) {
			const helper = opts.helper;
			const input = search_container.querySelector( 'input' );
			input.addEventListener( 'input', ( e ) => {
				const val = e.currentTarget.value;
				if ( val.length > 0 ) {
					hits_box.classList.remove( 'search__hits--hidden' );
					helper.setQuery( val ).search();
					return;
				}
				hits_box.classList.add( 'search__hits--hidden' );
			} );
		}
	};

	const hits_container = hits( {
		container: '.search__hits .container',
		cssClasses: {
			item: 'results'
		},
		templates: {
			empty: 'No results – try searching for something else?',
			allItems: function( all_items ) {
				/*
				Welcome. Because of the unique structure of this website, in
				order to display search results properly we have to use
				instantsearch's `allItems` template rather than `item`.

				Although we index matrix blocks, Algolia de-duplicates them by
				entry ID, so we'll only get one result per entry.

				This template gets passed every result, then we group them all
				by their own (level 2 entries) or their parent's (level 3)
				URIs. After we've done that, we can loop through all the
				parents and show the children underneath.

				Because of this grouping we lose Algolia's ordering slightly,
				but it's a trade-off for the functionality we need.
				*/
				const items_keyed_by_top_level = {};
				let str_to_render = '';
				for	( let i = 0; i < all_items.hits.length; i++ ) {
					const item = all_items.hits[i];
					if ( item.level === '2' ) {
						if ( items_keyed_by_top_level[item.uri_dedupe] === undefined ) {
							items_keyed_by_top_level[item.uri_dedupe] = {
								'title': item.title,
								'uri': item.uri_dedupe,
								'overviewText': item.overviewText,
								'children': [],
							};
						} else {
							items_keyed_by_top_level[item.uri_dedupe].overviewText = item.overviewText;
						}
					} else if ( item.level === '3' ) {
						if ( items_keyed_by_top_level[item.parent_uri] === undefined ) {
							items_keyed_by_top_level[item.parent_uri] = {
								'title': item.parent_title,
								'uri': item.parent_uri,
								'children': [],
							};
						}
						items_keyed_by_top_level[item.parent_uri].children.push( {
							'title': item.title,
							'uri': item.uri_dedupe,
						} );
					}
				}
				for ( const uri in items_keyed_by_top_level ) {
					str_to_render += '<div class="results">';
					str_to_render += '<h2 class="results__subtitle">' + createLink( items_keyed_by_top_level[uri].uri, items_keyed_by_top_level[uri].title ) + '</h2>';
					//if ( items_keyed_by_top_level[uri].overviewText ) {
					//	str_to_render += '<div class="typeset kappa">' + items_keyed_by_top_level[uri].overviewText + '</div>';
					//}
					if ( items_keyed_by_top_level[uri].children.length > 0 ) {
						str_to_render += '<ul class="results__list">';
						for	( let j = 0; j < items_keyed_by_top_level[uri].children.length; j++ ) {
							str_to_render += '<li class="results__item results__item--primary">' + createLink( items_keyed_by_top_level[uri].children[j].uri, items_keyed_by_top_level[uri].children[j].title ) + '</li>';
						}
						str_to_render += '</ul>';
					}
					str_to_render += '</div>';
				}
				return str_to_render;
			},
		}
	} );

	// If we're already on the same page as the search result, and the link
	// has a fragment, the link won't do anything. Let's make sure the search
	// closes itself when a result is clicked.
	hits_box.addEventListener( 'click', ( event ) => {
		if ( event.target instanceof HTMLAnchorElement ) {
			document.body.classList.remove( 'body-search-open' );
		}
	} );

	search.addWidgets( [ search_box, hits_container ] );
	search.start();

	function createLink( uri, label ) {
		return '<a href="/' + uri + '" class="a">' + label + '</a>';
	}
}
