/* eslint max-len: 0 */
import algoliasearchHelper from 'algoliasearch-helper';
import toFactory from 'to-factory';

/* eslint-disable import/no-unresolved */
import InstantSearch from './lib/InstantSearch.js';
import version from './lib/version.js';
/* eslint-enable import/no-unresolved */

// import instantsearch from 'instantsearch.js';
// -> provides instantsearch object without connectors and widgets
var instantSearchFactory = toFactory(InstantSearch);

instantSearchFactory.version = version;
instantSearchFactory.createQueryString = algoliasearchHelper.url.getQueryStringFromState;

Object.defineProperty(instantSearchFactory, 'widgets', {
  get: function get() {
    throw new ReferenceError('You can\'t access \'instantsearch.widgets\' directly from the ES6 build.\nImport the widgets this way: \'import {searchBox} from "instantsearch.js/es/widgets"\'');
  }
});

Object.defineProperty(instantSearchFactory, 'connectors', {
  get: function get() {
    throw new ReferenceError('You can\'t access \'instantsearch.connectors\' directly from the ES6 build.\nImport the connectors this way: \'import {connectSearchBox} from "instantsearch.js/es/connectors"\'');
  }
});

export default instantSearchFactory;
