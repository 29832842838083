var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

// we use the full path to the lite build to solve a meteor.js issue:
// https://github.com/algolia/instantsearch.js/issues/1024#issuecomment-221618284
import algoliasearch from 'algoliasearch/src/browser/builds/algoliasearchLite.js';
import algoliasearchHelper from 'algoliasearch-helper';
import forEach from 'lodash/forEach';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import isPlainObject from 'lodash/isPlainObject';
import EventEmitter from 'events';
import urlSyncWidget from './url-sync.js';
import RoutingManager from './RoutingManager.js';
import simpleMapping from './stateMappings/simple.js';
import historyRouter from './routers/history.js';
import version from './version.js';
import createHelpers from './createHelpers.js';
import { warn } from './utils';

var ROUTING_DEFAULT_OPTIONS = {
  stateMapping: simpleMapping(),
  router: historyRouter()
};

function defaultCreateURL() {
  return '#';
}
var defaultCreateAlgoliaClient = function defaultCreateAlgoliaClient(defaultAlgoliasearch, appId, apiKey) {
  return defaultAlgoliasearch(appId, apiKey);
};

var checkOptions = function checkOptions(_ref) {
  var appId = _ref.appId,
      apiKey = _ref.apiKey,
      indexName = _ref.indexName,
      createAlgoliaClient = _ref.createAlgoliaClient,
      searchClient = _ref.searchClient;

  if (!searchClient) {
    if (appId === null || apiKey === null || indexName === null) {
      var usage = '\nUsage: instantsearch({\n  appId: \'my_application_id\',\n  apiKey: \'my_search_api_key\',\n  indexName: \'my_index_name\'\n});';
      throw new Error(usage);
    }
  } else if (searchClient && (indexName === null || appId !== null || apiKey !== null || createAlgoliaClient !== defaultCreateAlgoliaClient)) {
    var _usage = '\nUsage: instantsearch({\n  indexName: \'my_index_name\',\n  searchClient: algoliasearch(\'appId\', \'apiKey\')\n});';
    throw new Error(_usage);
  }
};

/**
 * Widgets are the building blocks of InstantSearch.js. Any
 * valid widget must have at least a `render` or a `init` function.
 * @typedef {Object} Widget
 * @property {function} [render] Called after each search response has been received
 * @property {function} [getConfiguration] Let the widget update the configuration
 * of the search with new parameters
 * @property {function} [init] Called once before the first search
 */

/**
 * The actual implementation of the InstantSearch. This is
 * created using the `instantsearch` factory function.
 * @fires Instantsearch#render This event is triggered each time a render is done
 */

var InstantSearch = function (_EventEmitter) {
  _inherits(InstantSearch, _EventEmitter);

  function InstantSearch(options) {
    _classCallCheck(this, InstantSearch);

    var _this = _possibleConstructorReturn(this, (InstantSearch.__proto__ || Object.getPrototypeOf(InstantSearch)).call(this));

    var _options$appId = options.appId,
        appId = _options$appId === undefined ? null : _options$appId,
        _options$apiKey = options.apiKey,
        apiKey = _options$apiKey === undefined ? null : _options$apiKey,
        _options$indexName = options.indexName,
        indexName = _options$indexName === undefined ? null : _options$indexName,
        numberLocale = options.numberLocale,
        _options$searchParame = options.searchParameters,
        searchParameters = _options$searchParame === undefined ? {} : _options$searchParame,
        _options$urlSync = options.urlSync,
        urlSync = _options$urlSync === undefined ? null : _options$urlSync,
        _options$routing = options.routing,
        routing = _options$routing === undefined ? null : _options$routing,
        searchFunction = options.searchFunction,
        _options$createAlgoli = options.createAlgoliaClient,
        createAlgoliaClient = _options$createAlgoli === undefined ? defaultCreateAlgoliaClient : _options$createAlgoli,
        _options$stalledSearc = options.stalledSearchDelay,
        stalledSearchDelay = _options$stalledSearc === undefined ? 200 : _options$stalledSearc,
        _options$searchClient = options.searchClient,
        searchClient = _options$searchClient === undefined ? null : _options$searchClient;


    checkOptions({
      appId: appId,
      apiKey: apiKey,
      indexName: indexName,
      createAlgoliaClient: createAlgoliaClient,
      searchClient: searchClient
    });

    if (searchClient && typeof searchClient.search !== 'function') {
      throw new Error('InstantSearch configuration error: `searchClient` must implement a `search(requests)` method.');
    }

    var client = searchClient || createAlgoliaClient(algoliasearch, appId, apiKey);

    if (typeof client.addAlgoliaAgent === 'function') {
      client.addAlgoliaAgent('instantsearch.js ' + version);
    }

    _this.client = client;
    _this.helper = null;
    _this.indexName = indexName;
    _this.searchParameters = _extends({}, searchParameters, { index: indexName });
    _this.widgets = [];
    _this.templatesConfig = {
      helpers: createHelpers({ numberLocale: numberLocale }),
      compileOptions: {}
    };
    _this._stalledSearchDelay = stalledSearchDelay;

    if (searchFunction) {
      _this._searchFunction = searchFunction;
    }

    if (urlSync !== null) {
      if (routing !== null) {
        throw new Error('InstantSearch configuration error: it is not possible to use `urlSync` and `routing` at the same time');
      }

      warn('`urlSync` option is deprecated and will be removed in the next major version.\n' + 'You can now use the new `routing` option.');

      if (urlSync === true) {
        // when using urlSync: true
        warn('Use it like this: `routing: true`');
      }

      warn('For advanced use cases, checkout the documentation: https://community.algolia.com/instantsearch.js/v2/guides/routing.html#migrating-from-urlsync');
    }

    _this.urlSync = urlSync === true ? {} : urlSync;
    if (routing === true) _this.routing = ROUTING_DEFAULT_OPTIONS;else if (isPlainObject(routing)) _this.routing = _extends({}, ROUTING_DEFAULT_OPTIONS, routing);

    if (options.createAlgoliaClient) {
      warn('\n`createAlgoliaClient` option is deprecated and will be removed in the next major version.\nPlease use `searchClient` instead: https://community.algolia.com/instantsearch.js/v2/instantsearch.html#struct-InstantSearchOptions-searchClient.\nTo help you migrate, please refer to the migration guide: https://community.algolia.com/instantsearch.js/v2/guides/prepare-for-v3.html');
    }
    return _this;
  }

  /**
   * Adds a widget. This can be done before and after InstantSearch has been started. Adding a
   * widget after InstantSearch started is considered **EXPERIMENTAL** and therefore
   * it is possibly buggy, if you find anything please
   * [open an issue](https://github.com/algolia/instantsearch.js/issues/new?title=Problem%20with%20hot%20addWidget).
   * @param  {Widget} widget The widget to add to InstantSearch. Widgets are simple objects
   * that have methods that map the search life cycle in a UI perspective. Usually widgets are
   * created by [widget factories](widgets.html) like the one provided with InstantSearch.js.
   * @return {undefined} This method does not return anything
   */


  _createClass(InstantSearch, [{
    key: 'addWidget',
    value: function addWidget(widget) {
      this.addWidgets([widget]);
    }

    /**
     * Adds multiple widgets. This can be done before and after the InstantSearch has been started. This feature
     * is considered **EXPERIMENTAL** and therefore it is possibly buggy, if you find anything please
     * [open an issue](https://github.com/algolia/instantsearch.js/issues/new?title=Problem%20with%20addWidgets).
     * @param  {Widget[]} widgets The array of widgets to add to InstantSearch.
     * @return {undefined} This method does not return anything
     */

  }, {
    key: 'addWidgets',
    value: function addWidgets(widgets) {
      var _this2 = this;

      if (!Array.isArray(widgets)) {
        throw new Error('You need to provide an array of widgets or call `addWidget()`');
      }

      // The routing manager widget is always added manually at the last position.
      // By removing it from the last position and adding it back after, we ensure
      // it keeps this position.
      // fixes #3148
      var lastWidget = this.widgets.pop();

      widgets.forEach(function (widget) {
        // Add the widget to the list of widget
        if (widget.render === undefined && widget.init === undefined) {
          throw new Error('Widget definition missing render or init method');
        }

        _this2.widgets.push(widget);
      });

      // Second part of the fix for #3148
      if (lastWidget) this.widgets.push(lastWidget);

      // Init the widget directly if instantsearch has been already started
      if (this.started && Boolean(widgets.length)) {
        this.searchParameters = this.widgets.reduce(enhanceConfiguration({}), _extends({}, this.helper.state));

        this.helper.setState(this.searchParameters);

        widgets.forEach(function (widget) {
          if (widget.init) {
            widget.init({
              state: _this2.helper.state,
              helper: _this2.helper,
              templatesConfig: _this2.templatesConfig,
              createURL: _this2._createAbsoluteURL,
              onHistoryChange: _this2._onHistoryChange,
              instantSearchInstance: _this2
            });
          }
        });

        this.helper.search();
      }
    }

    /**
     * Removes a widget. This can be done after the InstantSearch has been started. This feature
     * is considered **EXPERIMENTAL** and therefore it is possibly buggy, if you find anything please
     * [open an issue](https://github.com/algolia/instantsearch.js/issues/new?title=Problem%20with%20removeWidget).
     * @param  {Widget} widget The widget instance to remove from InstantSearch. This widget must implement a `dispose()` method in order to be gracefully removed.
     * @return {undefined} This method does not return anything
     */

  }, {
    key: 'removeWidget',
    value: function removeWidget(widget) {
      this.removeWidgets([widget]);
    }

    /**
     * Removes multiple widgets. This can be done only after the InstantSearch has been started. This feature
     * is considered **EXPERIMENTAL** and therefore it is possibly buggy, if you find anything please
     * [open an issue](https://github.com/algolia/instantsearch.js/issues/new?title=Problem%20with%20addWidgets).
     * @param  {Widget[]} widgets Array of widgets instances to remove from InstantSearch.
     * @return {undefined} This method does not return anything
     */

  }, {
    key: 'removeWidgets',
    value: function removeWidgets(widgets) {
      var _this3 = this;

      if (!Array.isArray(widgets)) {
        throw new Error('You need to provide an array of widgets or call `removeWidget()`');
      }

      widgets.forEach(function (widget) {
        if (!_this3.widgets.includes(widget) || typeof widget.dispose !== 'function') {
          throw new Error('The widget you tried to remove does not implement the dispose method, therefore it is not possible to remove this widget');
        }

        _this3.widgets = _this3.widgets.filter(function (w) {
          return w !== widget;
        });

        var nextState = widget.dispose({
          helper: _this3.helper,
          state: _this3.helper.getState()
        });

        // re-compute remaining widgets to the state
        // in a case two widgets were using the same configuration but we removed one
        if (nextState) {
          // We don't want to re-add URlSync `getConfiguration` widget
          // it can throw errors since it may re-add SearchParameters about something unmounted
          _this3.searchParameters = _this3.widgets.filter(function (w) {
            return w.constructor.name !== 'URLSync';
          }).reduce(enhanceConfiguration({}), _extends({}, nextState));

          _this3.helper.setState(_this3.searchParameters);
        }
      });

      // If there's multiple call to `removeWidget()` let's wait until they are all made
      // and then check for widgets.length & make a search on next tick
      //
      // This solves an issue where you unmount a page and removing widget by widget
      setTimeout(function () {
        // no need to trigger a search if we don't have any widgets left
        if (_this3.widgets.length > 0) {
          _this3.helper.search();
        }
      }, 0);
    }

    /**
     * Clears the cached answers from Algolia and triggers a new search.
     *
     * @return {undefined} Does not return anything
     */

  }, {
    key: 'refresh',
    value: function refresh() {
      if (this.helper) {
        this.helper.clearCache().search();
      }
    }

    /**
     * Ends the initialization of InstantSearch.js and triggers the
     * first search. This method should be called after all widgets have been added
     * to the instance of InstantSearch.js. InstantSearch.js also supports adding and removing
     * widgets after the start as an **EXPERIMENTAL** feature.
     *
     * @return {undefined} Does not return anything
     */

  }, {
    key: 'start',
    value: function start() {
      var _this4 = this;

      if (!this.widgets) throw new Error('No widgets were added to instantsearch.js');

      if (this.started) throw new Error('start() has been already called once');

      var searchParametersFromUrl = void 0;

      if (this.urlSync) {
        var syncWidget = urlSyncWidget(this.urlSync);
        this._createURL = syncWidget.createURL.bind(syncWidget);
        this._createAbsoluteURL = function (relative) {
          return _this4._createURL(relative, { absolute: true });
        };
        this._onHistoryChange = syncWidget.onHistoryChange.bind(syncWidget);
        this.widgets.push(syncWidget);
        searchParametersFromUrl = syncWidget.searchParametersFromUrl;
      } else if (this.routing) {
        var routingManager = new RoutingManager(_extends({}, this.routing, {
          instantSearchInstance: this
        }));
        this._onHistoryChange = routingManager.onHistoryChange.bind(routingManager);
        this._createURL = routingManager.createURL.bind(routingManager);
        this._createAbsoluteURL = this._createURL;
        this.widgets.push(routingManager);
      } else {
        this._createURL = defaultCreateURL;
        this._createAbsoluteURL = defaultCreateURL;
        this._onHistoryChange = function () {};
      }

      this.searchParameters = this.widgets.reduce(enhanceConfiguration(searchParametersFromUrl), this.searchParameters);

      var helper = algoliasearchHelper(this.client, this.searchParameters.index || this.indexName, this.searchParameters);

      if (this._searchFunction) {
        this._mainHelperSearch = helper.search.bind(helper);
        helper.search = function () {
          var helperSearchFunction = algoliasearchHelper({
            search: function search() {
              return new Promise(function () {});
            }
          }, helper.state.index, helper.state);
          helperSearchFunction.once('search', function (state) {
            helper.overrideStateWithoutTriggeringChangeEvent(state);
            _this4._mainHelperSearch();
          });
          _this4._searchFunction(helperSearchFunction);
        };
      }

      this.helper = helper;
      this._init(helper.state, this.helper);
      this.helper.on('result', this._render.bind(this, this.helper));
      this.helper.on('error', function (e) {
        _this4.emit('error', e);
      });

      this._searchStalledTimer = null;
      this._isSearchStalled = true;

      this.helper.search();

      this.helper.on('search', function () {
        if (!_this4._isSearchStalled && !_this4._searchStalledTimer) {
          _this4._searchStalledTimer = setTimeout(function () {
            _this4._isSearchStalled = true;
            _this4._render(_this4.helper, _this4.helper.lastResults, _this4.helper.lastResults._state);
          }, _this4._stalledSearchDelay);
        }
      });

      // track we started the search if we add more widgets,
      // to init them directly after add
      this.started = true;
    }

    /**
     * Removes all widgets without triggering a search afterwards. This is an **EXPERIMENTAL** feature,
     * if you find an issue with it, please
     * [open an issue](https://github.com/algolia/instantsearch.js/issues/new?title=Problem%20with%20dispose).
     * @return {undefined} This method does not return anything
     */

  }, {
    key: 'dispose',
    value: function dispose() {
      this.removeWidgets(this.widgets);
    }
  }, {
    key: 'createURL',
    value: function createURL(params) {
      if (!this._createURL) {
        throw new Error('You need to call start() before calling createURL()');
      }
      return this._createURL(this.helper.state.setQueryParameters(params));
    }
  }, {
    key: '_render',
    value: function _render(helper, results, state) {
      var _this5 = this;

      if (!this.helper.hasPendingRequests()) {
        clearTimeout(this._searchStalledTimer);
        this._searchStalledTimer = null;
        this._isSearchStalled = false;
      }

      forEach(this.widgets, function (widget) {
        if (!widget.render) {
          return;
        }
        widget.render({
          templatesConfig: _this5.templatesConfig,
          results: results,
          state: state,
          helper: helper,
          createURL: _this5._createAbsoluteURL,
          instantSearchInstance: _this5,
          searchMetadata: {
            isSearchStalled: _this5._isSearchStalled
          }
        });
      });

      /**
       * Render is triggered when the rendering of the widgets has been completed
       * after a search.
       * @event InstantSearch#render
       */
      this.emit('render');
    }
  }, {
    key: '_init',
    value: function _init(state, helper) {
      var _this6 = this;

      forEach(this.widgets, function (widget) {
        if (widget.init) {
          widget.init({
            state: state,
            helper: helper,
            templatesConfig: _this6.templatesConfig,
            createURL: _this6._createAbsoluteURL,
            onHistoryChange: _this6._onHistoryChange,
            instantSearchInstance: _this6
          });
        }
      });
    }
  }]);

  return InstantSearch;
}(EventEmitter);

export function enhanceConfiguration(searchParametersFromUrl) {
  return function (configuration, widgetDefinition) {
    if (!widgetDefinition.getConfiguration) return configuration;

    // Get the relevant partial configuration asked by the widget
    var partialConfiguration = widgetDefinition.getConfiguration(configuration, searchParametersFromUrl);

    var customizer = function customizer(a, b) {
      // always create a unified array for facets refinements
      if (Array.isArray(a)) {
        return union(a, b);
      }

      // avoid mutating objects
      if (isPlainObject(a)) {
        return mergeWith({}, a, b, customizer);
      }

      return undefined;
    };

    return mergeWith({}, configuration, partialConfiguration, customizer);
  };
}

export default InstantSearch;