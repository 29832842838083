var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import qs from 'qs';

function defaultCreateURL(_ref) {
  var qsModule = _ref.qsModule,
      routeState = _ref.routeState,
      location = _ref.location;
  var protocol = location.protocol,
      hostname = location.hostname,
      _location$port = location.port,
      port = _location$port === undefined ? '' : _location$port,
      pathname = location.pathname,
      hash = location.hash;

  var queryString = qsModule.stringify(routeState);
  var portWithPrefix = port === '' ? '' : ':' + port;
  // IE <= 11 has no location.origin or buggy. Therefore we don't rely on it
  if (!routeState || Object.keys(routeState).length === 0) return protocol + '//' + hostname + portWithPrefix + pathname + hash;else return protocol + '//' + hostname + portWithPrefix + pathname + '?' + queryString + hash;
}

function defaultParseURL(_ref2) {
  var qsModule = _ref2.qsModule,
      location = _ref2.location;

  // `qs` by default converts arrays with more than 20 items to an object.
  // We want to avoid this because the data structure manipulated can therefore vary.
  // Setting the limit to `100` seems a good number because the engine's default is 100
  // (it can go up to 1000 but it is very unlikely to select more than 100 items in the UI).
  //
  // Using an `arrayLimit` of `n` allows `n + 1` items.
  //
  // See:
  //   - https://github.com/ljharb/qs#parsing-arrays
  //   - https://www.algolia.com/doc/api-reference/api-parameters/maxValuesPerFacet/
  return qsModule.parse(location.search.slice(1), { arrayLimit: 99 });
}

var BrowserHistory = function () {
  /**
   * Initializes a new storage provider that will sync the search state in the URL
   * using web API (window.location.pushState and onpopstate event).
   * @param {object} $0 the options.
   * @param {function(object):string} [$0.windowTitle] function that transforms a UI state
   * into a title for the page. It takes one parameter: a syncable object (generated by the mapping
   * provided to the URL sync). It should return a string that will be the title.
   * @param {number} [$0.writeDelay = 400] time before a write is actually done.
   * Prevent having too much entries in the history and thus make the back button more friendly.
   * @param {function(qs, object):string} [$0.createURL] generates the full URL. If not provided,
   * the storage adaptor will mapped all syncable keys to the query string of the URL. The first
   * parameter is a utility object that has two methods: `stringify` that creates a query string
   * from an object and `parse` that transforms a query string into an object.
   * @param {function(qs): object} [$0.parseURL] parses an URL into an object. It should symetrical
   * to `createURL`. It gets as an argument an object that contains two methods: `stringify` that
   * creates a query string from an object and `parse` that transforms a query string into an object.
   */
  function BrowserHistory() {
    var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        windowTitle = _ref3.windowTitle,
        _ref3$writeDelay = _ref3.writeDelay,
        writeDelay = _ref3$writeDelay === undefined ? 400 : _ref3$writeDelay,
        _ref3$createURL = _ref3.createURL,
        createURL = _ref3$createURL === undefined ? defaultCreateURL : _ref3$createURL,
        _ref3$parseURL = _ref3.parseURL,
        parseURL = _ref3$parseURL === undefined ? defaultParseURL : _ref3$parseURL;

    _classCallCheck(this, BrowserHistory);

    this.windowTitle = windowTitle;
    this.writeTimer = undefined;
    this.writeDelay = writeDelay;
    this._createURL = createURL;
    this.parseURL = parseURL;
  }

  /**
   * This method pushes a search state into the URL.
   * @param {object} routeState a syncable UI state
   * @return {undefined}
   */


  _createClass(BrowserHistory, [{
    key: 'write',
    value: function write(routeState) {
      var _this = this;

      var url = this.createURL(routeState);
      var title = this.windowTitle && this.windowTitle(routeState);

      if (this.writeTimer) {
        window.clearTimeout(this.writeTimer);
      }

      this.writeTimer = setTimeout(function () {
        if (title) window.document.title = title;
        window.history.pushState(routeState, title || '', url);
        _this.writeTimer = undefined;
      }, this.writeDelay);
    }

    /**
     * This methods read the URL and returns a syncable UI search state.
     * @return {object} the equivalent to what is store in the URL as an object
     */

  }, {
    key: 'read',
    value: function read() {
      return this.parseURL({ qsModule: qs, location: window.location });
    }

    /**
     * This methods sets a callback on the `onpopstate` event of the history API
     * of the current page. This way, the URL sync can keep track of the changes.
     * @param {function(object)} cb the callback that will receive the latest routeState.
     * It is called when the URL is updated.
     * @returns {undefined}
     */

  }, {
    key: 'onUpdate',
    value: function onUpdate(cb) {
      var _this2 = this;

      this._onPopState = function (event) {
        if (_this2.writeTimer) {
          window.clearTimeout(_this2.writeTimer);
          _this2.writeTimer = undefined;
        }
        var routeState = event.state;
        // at initial load, the state is read from the URL without
        // update. Therefore the state object is not there. In this
        // case we fallback and read the URL.
        if (!routeState) {
          cb(_this2.read());
        } else {
          cb(routeState);
        }
      };
      window.addEventListener('popstate', this._onPopState);
    }

    /**
     * This method creates a complete URL from a given syncable UI state.
     *
     * It always generates the full url, not a relative one.
     * This way we can handle cases like using a <base href>, see
     * https://github.com/algolia/instantsearch.js/issues/790 for the original issue
     *
     * @param {object} routeState a syncable UI state
     * @returns {string} the full URL for the provided syncable state
     */

  }, {
    key: 'createURL',
    value: function createURL(routeState) {
      return this._createURL({
        qsModule: qs,
        routeState: routeState,
        location: window.location
      });
    }

    /**
     * This method removes the event listener and cleans up the URL.
     * @returns {undefined}
     */

  }, {
    key: 'dispose',
    value: function dispose() {
      window.removeEventListener('popstate', this._onPopState);
      if (this.writeTimer) window.clearTimeout(this.writeTimer);
      this.write();
    }
  }]);

  return BrowserHistory;
}();

export default function () {
  for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  return new (Function.prototype.bind.apply(BrowserHistory, [null].concat(args)))();
}